import React from "react";
import {NormalText} from "./text/normal-text";
import {LargeText} from "./text/large-text";

export const BuisenessContentItem = ({icon, title, contents}) => (
    <div
        style={{
            position: "relative",
        }}
    >
        <div
            style={{
                position: "relative",
                zIndex: "1",
            }}
        >
            <div style={{
                margin: '30px 50px'
            }}>
                <img
                    src={icon}
                    style={{
                        position: "absolute",
                        top: "-30px",
                        left: "-30px",
                        width: '80px',
                        objectFit: 'contain',
                        zIndex: "0",
                    }}
                />
                <LargeText>{title}</LargeText>
            </div>
            <NormalText>{contents}</NormalText>
        </div>
    </div>
);
