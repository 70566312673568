import React from "react";

const CustomSection = ({id, height, bgColor, children}) => (
    <section
        id={id}
        style={{
            width: `100%`,
            height: height,
            backgroundColor: bgColor,
        }}
    >
        <div
            style={{
                padding: "80px 10%",
            }}
        >
            {children}
        </div>
    </section>
);

CustomSection.defaultProps = {
    height: `auto`,
    bgColor: `transparent`,
};

export default CustomSection;
