import React, {Children} from "react";
import {LargeText} from "./text/large-text";

export const PrimaryButton = ({width, height, label, onClick, bold}) => (
    <button
        style={{
            width: width,
            height: height,
            borderRadius: "10px",
            border: 'none',
            backgroundColor: "#71c18c",
        }}
        onClick={onClick}
    >
        <span
            style={{
                fontFamily: `Noto Sans JP`,
                fontSize: '18px',
                fontWeight: bold ? "700" : "300",
                fontStretch: `normal`,
                fontStyle: 'normal',
                lineHeight: `1.45`,
                letterSpacing: `normal`,
                textAlign: `left`,
                color: "white",
            }}
        >
    {label}
  </span>
    </button>
);

PrimaryButton.defaultProps = {
    width: "210px",
    height: "42px",
    onClick: () => {
        console.log("click");
    },
};
