import React from "react";
import { SectionTitleText } from "./text/section-title-text";
import { TableHeader } from "./table-header";
import { TelText } from "./text/tel-text";
import { Table } from "./table";
import { TableData } from "./table-data";
import {TableRow} from "./table-row";

export const GroupSection = () => (
  <div
    style={{
      minHeight: "40vh",
    }}
  >
    <SectionTitleText value="セントラルファーマシーグループ概要" />
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          flex: "0 1 500px",
        }}
      >
        <Table>
          <TableRow>
            <TableHeader>本部</TableHeader>
            <TableData>東京都杉並区西荻南3丁目6番10号</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>TEL</TableHeader>
            <TableData>
              <TelText value={"03-5346-1455 "} /> /
              <TelText value={"03-5346-1466"} />
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>代表取締役</TableHeader>
            <TableData>
              松成斉 (大阪薬科大学卒)
              <br />
              松成芽 (帝京大学薬学部卒)
            </TableData>
          </TableRow>
          <TableRow>
            <TableHeader>創業</TableHeader>
            <TableData>昭和58年4月</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>従業員数</TableHeader>
            <TableData>45名（パートタイマー含む）</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>年商</TableHeader>
            <TableData>10億円</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>取引銀行</TableHeader>
            <TableData>三井住友銀行　西荻窪支店</TableData>
          </TableRow>
        </Table>
      </div>
      <div
        style={{
          flex: "0 1 500px",
        }}
      >
        <Table>
          <TableRow>
            <TableHeader>社名</TableHeader>
            <TableData>株式会社セントラルファーマシー</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>代表取締役</TableHeader>
            <TableData>松成斉</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>創業</TableHeader>
            <TableData>昭和58年4月</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>資本金</TableHeader>
            <TableData>1000万円</TableData>
          </TableRow>
        </Table>
        <Table>
          <TableRow>
            <TableHeader>社名</TableHeader>
            <TableData>有限会社アデムファーマシー</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>代表取締役</TableHeader>
            <TableData>松成芽</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>創業</TableHeader>
            <TableData>平成5年12月28日</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>資本金</TableHeader>
            <TableData>300万円</TableData>
          </TableRow>
        </Table>
        <Table>
          <TableRow>
            <TableHeader>社名</TableHeader>
            <TableData>有限会社メディコムファーマシー</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>代表取締役</TableHeader>
            <TableData>松成斉</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>創業</TableHeader>
            <TableData>平成5年2月8日</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>資本金</TableHeader>
            <TableData>300万円</TableData>
          </TableRow>
        </Table>
      </div>
    </div>
  </div>
);
