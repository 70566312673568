import React from "react";
import {NormalText} from "./text/normal-text";

export const TableData = ({children}) => (
    <td
        style={{
            border: "none",
            paddingLeft: '15px'
        }}
    >
        <NormalText>{children}</NormalText>
    </td>
);
