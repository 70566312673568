import React from "react";
import logo from "../images/logo.svg";
import town from "../images/town.svg";
import {NormalText} from "./text/normal-text";
import {MediaContextProvider, Media} from "../media-query"

export const Footer = () => (
    <footer>
        <div
            style={{
                textAlign: 'center',
                position: 'relative',
                zIndex: '-1',
            }}
        >
            <MediaContextProvider>
                <Media greaterThanOrEqual={"md"}>
                    <img
                        src={town}
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            margin: `auto`,
                            width: `60%`,
                        }}
                    />
                </Media>
                <Media lessThan={'md'}>
                    <img
                        src={town}
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            margin: `auto`,
                            width: `80%`,
                        }}
                    />
                </Media>
            </MediaContextProvider>
        </div>
        <div
            style={{
                height: `180px`,
                backgroundColor: `#f0f9f3`,
            }}
        >
            <img
                src={logo}
                style={{
                    display: `block`,
                    margin: `auto`,
                    paddingTop: `50px`,
                    width: `150px`,
                    objectFit: `contain`,
                }}
            />
            <div style={{
                width: '100%',
                textAlign: 'center'
            }}>
                <NormalText>© {new Date().getFullYear()} central pharmacy.</NormalText>
            </div>

        </div>
    </footer>
);
