import React from "react";
import CustomSection from "../components/section";
import Header from "../components/header";
import {Footer} from "../components/footer";
import {TopLayout as TopSection} from "../components/section-top";
import {BuisenessContentLayout as BusinessSection} from "../components/section-buisiness";
import {SectionStore as StoresSection} from "../components/section-store";
import {ProductSection} from "../components/section-products";
import {GroupSection} from "../components/section-group";
import {Helmet} from "react-helmet";
import {mediaStyle} from "../media-query";

const IndexPage = () => (
    <>
        <Helmet>
            <meta name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"/>
            <title>セントラルファーマシーグループ</title>
            <style type={"text/css"}>{mediaStyle}</style>
        </Helmet>
        <Header/>
        <CustomSection id={"top"}>
            <TopSection/>
        </CustomSection>
        <CustomSection id={"business"} bgColor={"#f0f9f3"}>
            <BusinessSection/>
        </CustomSection>
        <CustomSection id={"stores"}>
            <StoresSection/>
        </CustomSection>
        <CustomSection bgColor={"#f0f9f3"} id={"product"}>
            <ProductSection/>
        </CustomSection>
        <CustomSection id={"group"}>
            <GroupSection/>
        </CustomSection>
        <Footer/>
    </>
);

export default IndexPage;
