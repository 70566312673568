import React from "react";
import { SectionTitleText } from "./text/section-title-text";
import { BuisenessContentItem } from "./buiseness-content-item";
import iconDispensing from "../images/icon-dispensing.svg";
import iconOTC from "../images/icon-otc.svg";
import iconHome from "../images/icon-home.svg";
import { SectionDescription } from "./text/section-description";

export const BuisenessContentLayout = () => (
  <div
    style={{
      minHeight: "60vh",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    }}
  >
    <div>
      <SectionTitleText value="事業内容" />
      <SectionDescription>
        昭和58年より、調剤薬局として質の高い医療分業は質の高い薬剤師からをモットーに
        常に知識の研鑽と、真心のこもった患者さんへの対応で、
        信頼されるかかりつけの薬局として地域の医療に貢献しています。
        <br />
        地域の医療機関と連携しながら、あなたの健康管理をサポートします。
        <br />
      </SectionDescription>      
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "0 1 500px",
        padding: "50px 0",
      }}
    >
      <BuisenessContentItem
        icon={iconDispensing}
        title="調剤"
        contents="内科、小児科、整形外科、耳鼻咽喉科、皮膚科、眼科、精神神経内科、歯科など、全ての医療機関の処方箋を応需しています。"
      />
      <BuisenessContentItem
        icon={iconOTC}
        title="OTC"
        contents="処方箋なしに購入できる、要指導医薬品、第1類から第3類までのお薬を揃え、薬剤師により提供しています。"
      />
      <BuisenessContentItem
        icon={iconHome}
        title="在宅訪問"
        contents="医師と連携をとりながら、在宅療養されている方への訪問服薬指導を行っています。"
      />
    </div>
  </div>
);
