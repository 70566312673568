import React from "react";
import {SectionTitleText} from "./text/section-title-text";
import ProductData from "../../data/product.json";
import {ProductItem} from "./product-item";
import {SectionDescription} from "./text/section-description";

export const ProductSection = () => (
    <div
        style={{
            minHeight: "60vh",
            height: "auto",
        }}
    >
        <SectionTitleText value="取り扱い商品"/>
        <SectionDescription>
            OTC医薬品をそれぞれの店舗で取り扱っております。
            <br/>
            下記商品以外にも、ガスター10..ロキソニンS．ロキソニンSテープ、アレグラＦＸ.リアップX5.ミノグロウ（リアップのジェネリック）等を取り扱っております。
        </SectionDescription>
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 350px))",
                margin: "20px auto",
                maxWidth: "1200px",
                gridRowGap: "20px",
                gridColumnGap: "20px"
            }}
        >
            {ProductData.data.map((item, index) => (
                <ProductItem
                    key={'product-' + index}
                    image={item.img}
                    title={item.name}
                    content={item.description}
                />
            ))}
        </div>
    </div>
);
