import React from "react";
import {NormalText} from "./text/normal-text";

export const TableHeader = ({children}) => (
    <th
        style={{
            border: 'none',
            borderRight: 'solid 3px #71c18c',
            width: '30%',
            padding: '0px',
            textAlign: 'left'
        }}
    >
        <NormalText>{children}</NormalText>
    </th>
);
