import React from "react";
import logo from "../images/logo.svg";
import topImage from "../images/top-image.svg";
import { CatchText } from "./text/catch-text";
import {ContactButton} from "./contact-button";

export const TopLayout = () => (
  <div
    style={{
      display: "flex",
      minHeight: "80vh",
      flexWrap: "wrap",
      justifyContent: "space-around",
    }}
  >
    <div
      style={{
        flex: "0 1 500px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img src={logo} />
      <CatchText>
        地域の皆様の健康をサポートする
        <br />
        セントラルファーマシーグループ
      </CatchText>
      <div style={{
        margin: 'auto'
      }}>
          <ContactButton/>
      </div>
    </div>
    <div
      style={{
        flex: "0 1 500px",
      }}
    >
      <img
        src={topImage}
        style={{
          width: "100%",
        }}
      />
    </div>
  </div>
);
