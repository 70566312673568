import PropTypes from "prop-types";
import React, {useState} from "react";
import {HeaderLinkButton} from "./header-link-button";
import {Drawer} from "@material-ui/core";
import {Menu} from "@material-ui/icons";
import {ContactButton} from "./contact-button";

const Header = () => {
    const [open, setOpen] = useState(false);
    return (
        <header
            style={{
                marginBottom: `1.45rem`,
                position: 'fixed',
                width: '100%',
                height: '50px',
                top: '0',
            }}
        >
            <div style={{
                textAlign: 'right',
                padding: '1em'
            }}>
                <Menu
                    style={{
                        color: "#71c18c"
                    }}
                    onClick={() => setOpen(!open)}
                />
            </div>
            <Drawer open={open}
                    onClose={() => {setOpen(!open)}}
                    anchor={"right"}
            >
                <div
                    onClick={() => setOpen(!open)}
                    onKeyDown={() => setOpen(!open)}
                    style={{
                        display: `flex`,
                        flexDirection: "column",
                        padding: `1.45rem 1.0875rem`,
                        marginTop: '30px',
                        width: '300px',
                        height: '100%'
                    }}
                >
                    <HeaderLinkButton value="事業内容" linkTo="#business"/>
                    <HeaderLinkButton value="薬局" linkTo="#stores"/>
                    <HeaderLinkButton value="取り扱い商品" linkTo="#product"/>
                    <HeaderLinkButton value="グループ概要" linkTo="#group"/>
                    <div style={{
                        marginTop: 'auto'
                    }}>
                        <ContactButton/>
                    </div>
                </div>
            </Drawer>
        </header>
    );
}

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
