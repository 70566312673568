import React from "react";
import {StaticImage} from "./static-image";
import {NormalText} from "./text/normal-text";
import {LargeText} from "./text/large-text";

export const ProductItem = ({title, content, image}) => (
    <div
        style={{
            backgroundColor: "white",
            padding: "2em",
        }}
    >
        <StaticImage path={image}/>
        <LargeText bold={true}>{title}</LargeText>
        <NormalText>{content}</NormalText>
    </div>
);
