import React from "react";

export const LargeText = ({ children, bold=false, color }) => (
  <p
    style={{
      fontFamily: `Noto Sans JP`,
      fontSize: `25px`,
      fontWeight: bold ? `500` : `300`,
      fontStretch: `normal`,
      fontStyle: `normal`,
      lineHeight: `1.48`,
      letterSpacing: `normal`,
      textAlign: `left`,
      color: color,
    }}    
  >
      {children}
  </p>
);

LargeText.defaultProps = {
    color: `#707070`
}