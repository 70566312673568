import React from "react";
import { NormalText } from "./normal-text";

export const TelText = ({ value }) => (
  <a href={"tel:" + { value }} style={{
      textDecoration: 'none'
  }}>
    <NormalText>{value}</NormalText>
  </a>
);
