import React from "react";
import {NormalText} from "./text/normal-text";
import iconMap from "../images/icon-map.svg";
import iconPhone from "../images/icon-phone.svg";
import iconFax from "../images/icon-fax.svg";
import {IconedText} from "./icon-text";
import {TelText} from "./text/tel-text";
import {PrimaryButton} from "./primary-button";

export const StoreItem = ({name, address, tel, fax, mapQuery}) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "400px",
            flex: "0 1 300px",
        }}
    >
        <div
            style={{
                padding: '10px 0'
            }}
        >
            <NormalText bold={true}>{name}</NormalText>
        </div>

        <div style={{
            padding: '5px 0'
        }}>
            <IconedText icon={iconMap}>{address}</IconedText>
        </div>

        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                padding: '5px 0'
            }}
        >
            <div style={{
                paddingRight: '15px'
            }}>
                <IconedText icon={iconPhone}>
                    <TelText value={tel}/>
                </IconedText>
            </div>

            <IconedText icon={iconFax}>
                <TelText value={fax}/>
            </IconedText>
        </div>
        <div style={{
            padding: "10px"
        }}>
            <a href={"https://www.google.com/maps/search/?api=1&query=" + mapQuery}>
                <PrimaryButton label={"Mapを開く"}/>
            </a>
        </div>
        <div style={{
            padding: '10px'
        }}>
            <a href={"tel:" + tel}>
                <PrimaryButton label={"電話をかける"}/>
            </a>
        </div>
    </div>
);
