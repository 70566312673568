import React from 'react';
import {PrimaryButton} from "./primary-button";

export const ContactButton = () => (
    <a href={"mailto:info@adem-pharmacy.co.jp?" +
        //件名：お問い合わせの概要をご入力ください
    "subject=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E3%81%AE%E6%A6%82%E8%A6%81%E3%82%92%E3%81%94%E5%85%A5%E5%8A%9B%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84" +
        //body:お問い合わせ内容をご入力ください。
    "&body=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%E3%82%92%E3%81%94%E5%85%A5%E5%8A%9B%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82"}>
        <PrimaryButton
            width="250px"
            height="49px"
            label="お問い合わせはこちら"
            bold={true}
        />
    </a>
);
