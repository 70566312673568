import React from "react";
import { NormalText } from "./text/normal-text";

export const IconedText = ({ icon, children }) => (
  <div
    style={{
      display: "flex",
      alignItems: "baseline",
    }}
  >
    <div style={{paddingRight: '5px'}}>
      <img src={icon} style={{margin: '0'}}/>
    </div>
    <NormalText>{children}</NormalText>
  </div>
);
