import React from 'react';
import { NormalText } from './normal-text';

export const SectionDescription = ({children}) => (
    <div style={{
        margin: '2em 0',
        maxWidth: '600px'                
    }}>
        <NormalText>
            {children}
        </NormalText>
    </div>
)