import React from "react";
import {Link} from "gatsby";
import {NormalText} from "./text/normal-text";

export const HeaderLinkButton = ({value: value, linkTo}) => (
    <Link
        to={linkTo}
        style={{
            textDecoration: `none`,
            margin: `10px 10px`,
            flex: '0 0 30px',
            textAlign: 'center'
        }}
    >
        <NormalText>
            {value}
        </NormalText>
    </Link>
);
