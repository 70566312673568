import React from "react";

export const Table = ({children}) => (
    <table
        style={{
            margin: '0',
            padding: '0',
            marginBottom: '50px',
            fontSize: '1rem',
            lineHeight: '1.45rem',
            borderCollapse: 'collapse',
            width: '100%',
            border: "none"
        }}
    >
        <tbody>{children}</tbody>
    </table>
);
