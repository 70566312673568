import React from "react";
import {MediaContextProvider, Media} from '../../media-query'

export const SectionTitleText = ({value}) => (
    <div
        style={{
            textAlign: "left",
            color: "#707070",
        }}
    >
        <MediaContextProvider>
            <Media greaterThanOrEqual={'lg'}>
                <h2
                    style={{
                        width: "fit-content",
                        borderBottom: "solid 4px #71c18c",
                        padding: '5px 10px',
                        fontSize: "32px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontFamily: "NotoSansJP",
                        fontStyle: "normal",
                        lineHeight: "1.48",
                        letterSpacing: "normal",
                    }}
                >
                    {value}
                </h2>
            </Media>
            <Media lessThan={'lg'}>
                <h2
                    style={{
                        width: "fit-content",
                        borderBottom: "solid 4px #71c18c",
                        padding: '5px 10px',
                        fontSize: "25px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontFamily: "NotoSansJP",
                        fontStyle: "normal",
                        lineHeight: "1.48",
                        letterSpacing: "normal",
                    }}
                >
                    {value}
                </h2>
            </Media>
        </MediaContextProvider>
    </div>
);
