import React from "react";

export const NormalText = ({children, bold = false, fontSize, color}) => (
    <span
        style={{
            fontFamily: `Noto Sans JP`,
            fontSize: fontSize,
            fontWeight: (bold ? `500` : '300'),
            fontStretch: `normal`,
            fontStyle: 'normal',
            lineHeight: `1.45`,
            letterSpacing: `normal`,
            textAlign: `left`,
            color: color,
        }}
    >
    {children}
  </span>
);


NormalText.defaultProps = {
    color: `#707070`,
    fontSize: '1em',
}

