import React from "react";
import {SectionTitleText} from "./text/section-title-text";
import {StoreItem} from "./store-item";
import StoreData from "../../data/stores.json";
import {SectionDescription} from "./text/section-description";
import {StoreNameText} from './text/store-name-text';
import {MediaContextProvider, Media} from "../media-query"

export const SectionStore = () => (
    <div
        style={{
            minHeight: "60vh",
        }}
    >
        <SectionTitleText value="薬局"/>
        <SectionDescription>
            杉並区を中心に、セントラルファーマシー、茜調剤薬局など、多岐にわたる展開をしております。
            <br/>
            お近くの薬局をご利用ください。
            <br/>
            各店舗処方箋をFAXにて受付けも行っております。
            <br/>
            送信いただいたFAXを確認後、不明な点がある場合にはこちらからご連絡することもありますので、ご連絡先、電話番号もお知らせください。
            <br/>
        </SectionDescription>
        {StoreData.data.map((data, index) => {
            return (
                <div
                    key={"store-section-" + index}
                >
                    <StoreNameText>{data.name}</StoreNameText>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: 'wrap',
                            justifyContent: 'start',
                        }}
                    >
                        {data.stores.map((data, index) => {
                            return (
                                <div
                                    key={"stores-" + index}
                                    style={{
                                        paddingRight: '30px',
                                        paddingBottom: '30px'
                                    }}>
                                    <StoreItem
                                        name={data.storeName}
                                        tel={data.phone}
                                        fax={data.fax}
                                        address={data.address}
                                        mapQuery={data.mapQuery}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        })}
    </div>
);
