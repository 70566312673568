import React from "react";
import {MediaContextProvider, Media} from '../../media-query';

export const CatchText = ({ children }) => (
    <MediaContextProvider>
        <Media greaterThanOrEqual={"lg"}>
            <h1
                style={{
                    fontFamily: "Noto Sans JP",
                    fontSize: "30px",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.5",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#707070",
                }}
            >
                {children}
            </h1>
        </Media>
        <Media lessThan={"lg"}>
            <h1
                style={{
                    fontFamily: "Noto Sans JP",
                    fontSize: "24px",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.5",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#707070",
                }}
            >
                {children}
            </h1>
        </Media>
    </MediaContextProvider>
);
